import React, { useState } from "react";
import { connect } from "react-redux";
import "../assets/css/authentication/form-2.css";
import { deleteAccount } from "../store/Admin/admin.action";

import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";

const DeleteAccount = (props) => {
  const [fullName, setfullName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");

  const deleteAccountSubmit = () => {
    if (!fullName || !email || !reason) {
      let error = {};
      if (!fullName) error.fullName = "Name Is Required !";
      if (!email) error.email = "Email Is Required !";
      if (!reason) error.reason = "Reason Is Required !";
      return setError({ ...error });
    } else {
      let accountData = {
        fullName,
        email,
        reason,
      };

      props.deleteAccount(accountData);
    }
  };



  return (
    <>
      <div class="form-container outer">
        <div class="form-form">
          <div class="form-form-wrap">
            <div class="form-container">
              <div class="form-content" style={{ backgroundColor: "#312a44" }}>
                <div>
                  <img
                    src={logo}
                    style={{
                      width: "120px",
                      height: "120px",
                    }}
                    alt=""
                    className="mx-auto"
                    draggable="false"
                  />
                </div>
                <h2 class="fw-bold mt-2">Delete Account Request</h2>
                {/* <h6 className="text-muted">
                  Enter your email and password to access admin panel.
                </h6> */}

                <form class="text-left">
                  <div class="form">
                  <div id="name-field" class="field-wrapper input">
                      <label for="name" style={{ fontSize: "15px" }}>
                        Full Name
                      </label>

                      <input
                        className="text-white"
                        id="name"
                        name="name"
                        type="text"
                        value={fullName}
                        class="form-control p-3"
                        placeholder="Name"
                        onChange={(e) => {
                          setfullName(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              fullName: "Name Is Required !",
                            });
                          } else {
                            return setError({
                              ...error,
                              fullName: "",
                            });
                          }
                        }}
                       
                      />
                      {error.email && (
                        <span className="text-danger">{error.email}</span>
                      )}
                    </div>

                    <div id="email-field" class="field-wrapper input">
                      <label for="email" style={{ fontSize: "15px" }}>
                        Email
                      </label>

                      <input
                        className="text-white"
                        id="email"
                        name="email"
                        type="Email"
                        value={email}
                        class="form-control p-3"
                        placeholder="Email"
                        onChange={(e) => {
                          setEmail(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              email: "Email Is Required !",
                            });
                          } else {
                            return setError({
                              ...error,
                              email: "",
                            });
                          }
                        }}
                       
                      />
                      {error.email && (
                        <span className="text-danger">{error.email}</span>
                      )}
                    </div>

                    <div id="reason-field" class="field-wrapper input mb-2">
                      <label for="reason" style={{ fontSize: "15px" }}>
                        Reason
                      </label>
                      <textarea class="form-control" placeholder="Leave a reason here" id="reason" name="reason" style={{height: "150px"}}
                      value={reason}
                      onChange={(e) => {
                        setReason(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...error,
                            reason: "Reason Is Required !",
                          });
                        } else {
                          return setError({
                            ...error,
                            reason: "",
                          });
                        }
                      }}
                      ></textarea>
                      {error.reason && (
                        <span className="text-danger">{error.reason}</span>
                      )}
                    </div>

                  
                    <div class="row">
                      <div class="field-wrapper">
                        <button
                          type="button"
                          class="btn text-white btnSubmit"
                          onClick={deleteAccountSubmit}
                          value=""
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { deleteAccount })(DeleteAccount);
