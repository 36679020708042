// get_host_request
export const GET_HOST_REQUEST = "GET_HOST_REQUEST";
//Handle Open Close Dialog
export const OPEN_HOST_REQUEST_DIALOG = "OPEN_HOST_REQUEST_DIALOG";

// handle close Dialog
export const CLOSE_HOST_REQUEST_DIALOG = "CLOSE_HOST_REQUEST_DIALOG";

// handel Edit host Request
export const EDIT_HOST_REQUEST = "EDIT_HOST_REQUEST";

// handle host request accept 
export const ACCEPT_HOST_REQUEST = "ACCEPT_HOST_REQUEST";   
