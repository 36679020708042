import axios from "axios";
import { setToast } from "../../utils/toast";
import * as ActionType from "./deleteRequest.type";

// get host request
export const getDeleteRequest = () => (dispatch) => {
    axios
        .get("deleteRequest/getRequests")
        .then((res) => {
            dispatch({
                type: ActionType.GET_DELETE_REQUEST,
                payload: res.data.requestAll,
            });
        })
        .catch((error) => console.log("error", error));
};

// host Request accept
export const deleteRequestAccept = (id) => (dispatch) => {;
    axios
        .put(`deleteRequest/${id}`)
        .then((res) => {;
            console.log(res.data);
            if (res.data.status) {
                dispatch({ type: ActionType.ACCEPT_DELETE_REQUEST, payload: id });
                setToast("success", "Delete Request Accept!");
            }
        })
        .catch((error) => console.log("error", error));
};