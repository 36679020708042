import * as ActionType from "./deleteRequest.type";

const initialState = {
  deleteRequest: [],
  dialog: false,
  dialogData: null,
};

export const deleteRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_DELETE_REQUEST:
      return {
        ...state,
        deleteRequest: action.payload,
      };

    case ActionType.ACCEPT_DELETE_REQUEST:
      return {
        ...state,
        deleteRequest: state.deleteRequest.filter(
          (deleteAccept) => deleteAccept._id !== action.payload && deleteAccept
        ),
      };
    default:
      return state;
  }
};
