// GET_GIFT_CATEGORY
export const GET_GIFT_CATEGORY = "GET_GITF_CATEGORY";

// OPEN_CATEGORY_DIALOG
export const OPEN_CATEGORY_DIALOG = "OPEN_CATEGORY_DIALOG";

// CLOSE_CATEGORY_DIALOG
export const CLOSE_CATEGORY_DIALOG = "CLOSE_CATEGORY_DIALOG";

// CREATE_GIFT_CATEGORY
export const CREATE_GIFT_CATEGORY = "CREATE_GIFT_CATEGORY";

// UPDATE_GIFT_CATEGORY
export const EDIT_GIF_CATEGORY = "EDIT_GIF_CATEGORY";

// DELETE_GIFT_CATEGORY
export const DELETE_GIF_CATEGORY = "DELETE_GIF_CATEGORY";

// IS_ACTIVE_GIFT_CATEGORY
export const IS_ACTIVE_GIF_CATEGORY = "IS_ACTIVE_GIF_CATEGORY";
