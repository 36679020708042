export const GET_GIFT = "GET_GIFT";

export const CREATE_NEW_GIFT = "CREATE_NEW_GIFT";

export const EDIT_GIFT = "EDIT_GIFT";

export const DELETE_GIFT = "DELETE_GIFT";


export const OPEN_GIFT_DIALOG = "OPEN_GIFT_DIALOG";

export const CLOSE_GIFT_DIALOG = "CLOSE_GIFT_DIALOG";
